export default {
  // 项目标题
  title: '天豹500ml拆箱扫码得红包',
  // 加载远程图
  loadRemotePic: true,
  // 埋点配置
  tracking: {
    url: process.env.VUE_APP_BASE_API + '/osa/logAgent/save',
    scene: {
      //页面进入前
      before: {
        // 路由名称
        turntable: {
          userVisit: 6
        },
        redEnvelopes: {
          userVisit: 17
        }
      },
      //页面进入后
      after: {},
      //返回页面
      back: {},
      //页面所有接口请求后埋点
      nextRequest: {
        turntable: {
          userVisit: 2
        }
      }
    }
  }
}
